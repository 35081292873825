import React from "react"
import {Form} from 'react-bootstrap'
import styled from 'styled-components'

const CurrencySymbol = styled.span`
  grid-area: 1/1;
  color: ${({theme}) => theme.text};
  padding: auto;
  text-align: right;
`

const InputStyled = styled(Form.Control)`
  text-align: left;
  background: ${({theme}) => theme.inputBg};
  color: ${({theme}) => theme.text};
  border: 0;

  &:focus{
    background: ${({theme}) => theme.inputBg};
    color: ${({theme}) => theme.text};
  }

  &:focus-visible{
    border: 0;
  }
`

const Wrap = styled.span`
  display: grid;
  background-color: ${({theme}) => theme.inputBg};
  border-radius: 10px;
`
export function CurrencyField({ symbol, symbolAtEnd, style, className, ...props}) {
  return (
    <Wrap className={[className, symbolAtEnd].filter(Boolean).join(" ")} style={style} >
      <CurrencySymbol>{symbol}</CurrencySymbol>
      <InputStyled type="numeric" data-currency={symbol} {...props} />
    </Wrap>
  )
}
