import * as React from "react"

import { Icon } from '@iconify/react';
import {AcCustom, AcItem, AcHeader, ClearBtn, AcBody, CustomCheck} from './check-filter.styles'


export function CheckFilter({ items, name, selectedItems = [], setSelectedItems}) {
  const toggleItem = ({ currentTarget: input }) => {
    if (input.checked) {
      setSelectedItems([...selectedItems, input.value])
    } else {
      const idx = selectedItems.indexOf(input.value)
      if (idx === -1) {
        return
      }
      const newItems = [
        ...selectedItems.slice(0, idx),
        ...selectedItems.slice(idx + 1),
      ]
      setSelectedItems(newItems)
    }
  }

  const clearItems = () => setSelectedItems([])

  return (
    <AcCustom defaultActiveKey="0" className="my-3" >
      <AcItem eventKey="0">
        {name && (
          <AcHeader >
              {name}{" "}
              {selectedItems.length ? (
                <ClearBtn  onClick={clearItems}>
                  <Icon icon="bi:x-lg" width="10px" height="10px" />
                </ClearBtn>
              ) : undefined}
          </AcHeader>
        )}
        <AcBody className="d-flex flex-column">
          {items.map((item, i) => (
            <div key={item+i} className="d-flex flex-row">
              <CustomCheck type="checkbox" className="me-2" onChange={toggleItem} value={item} checked={selectedItems.includes(item)} />{" "}
              {item || "None"}
            </div>
          ))}
        </AcBody>
      </AcItem>
    </AcCustom>
  )
}
