import React from 'react'
import { InputGroup } from 'react-bootstrap'
import debounce from "debounce"

import { InlineIcon } from '@iconify/react';

import { SearchForm, FormControlStyled, ClearSearch } from './searchBar.styles'

const SearchBar = ({ defaultTerm, setFilters }) => {
    const [term, setTerm] = React.useState(defaultTerm)
    const debouncedSetFilters = React.useMemo(
      () =>
      debounce((value) => {
        setFilters((filters) => ({ ...filters, term: value }))
      }, 200),
      [setFilters] // eslint-disable-line react-hooks/exhaustive-deps
    )
  
    return (
      <SearchForm onSubmit={(e) => e.preventDefault()} >
        <InputGroup className="mb-3">
          <FormControlStyled type="text" placeholder="Search" aria-label="Search Input" aria-describedby="basic-addon2"
            value={term}
            onChange={(e) => {
              setTerm(e.target.value)
              debouncedSetFilters(e.target.value)
            }}
          />
          {term ? (
            <ClearSearch type="reset" aria-label="Clear search query"
              onClick={() => {
                setTerm("")
                setFilters((filters) => ({ ...filters, term: "" }))
              }}>
              <InlineIcon icon="bi:x-circle-fill" /> Clear
            </ClearSearch>
          ) : undefined}
        </InputGroup>
      </SearchForm>
    )
  }

  export default SearchBar