import React from 'react'
import { Card } from 'react-bootstrap'

import { graphql } from "gatsby"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../../utils/format-price"

import {CardLink, CardStyled, GatsbyImg, VendorName, ProductTitle, Price} from './card-grid.styled'

const CardGrid = ({product, eager}) => {

    const {
        title,
        priceRangeV2,
        slug,
        images: [firstImage],
        vendor,
        storefrontImages,
      } = product
    
      const price = formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        priceRangeV2.minVariantPrice.amount
      )
    
      let storefrontImageData = {}
      if (storefrontImages) {
        const storefrontImage = storefrontImages.edges[0].node
        try {
          storefrontImageData = getShopifyImage({
            image: storefrontImage,
            layout: "fixed",
            width: 200,
            height: 200,
          })
        } catch (e) {
          console.error(e)
        }
      }

    return(
      <CardLink to={slug} aria-label={`View ${title} product page`}>
          <CardStyled>
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <GatsbyImg
                  className="card-img-top"
                  alt={firstImage?.altText ?? title}
                  image={firstImage?.gatsbyImageData ?? storefrontImageData}
                  loading={eager ? "eager" : "lazy"}
                  />
              <Card.Body>
                  <div style={{height: '5rem'}} className="d-flex flex-column">
                    <VendorName>{vendor}</VendorName>
                    <ProductTitle as={"span"}>{title}</ProductTitle>
                  </div>
                  <Price>{price}</Price>
                  {/* <AddtoCart>Add to Cart</AddtoCart> */}
              </Card.Body>
          </CardStyled> 
      </CardLink>
    )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    images {
      id
      altText
      gatsbyImageData(
        aspectRatio: 1
        width: 240
        )
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    vendor
  }

  fragment ProductCardForCollection on ShopifyCollection {
    products{
      id
      title
      slug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
      )
      images {
        id
        altText
        gatsbyImageData(
          aspectRatio: 1
          width: 240
          )
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      vendor
    }
  }

`

export default CardGrid