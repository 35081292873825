import styled from 'styled-components'
import {Card, Button} from 'react-bootstrap'

import {Link} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

export const CardLink = styled(Link)`
    text-decoration: none;
`;

export const CardStyled = styled(Card)`
    width: 12em;
    height: 100%;
    margin: 0;
    background: ${({theme}) => theme.cardBg};
    color: ${({theme}) => theme.text};
    border-radius: 15px;

    .card-img-top{
      img{
        border-radius: 10px;
      }
    }

    &:hover{
        // box-shadow:  20px 20px 60px #161616, -20px -20px 60px #2c2c2c;

    }
`;

export const CardStyledV = styled(Card)`
    width: 100%;
    margin: 0;
    height: ${props => props.heightrem ? props.heightrem : `auto`};
    background: ${({theme}) => theme.cardBg};
    color: ${({theme}) => theme.text};
    border-radius: 15px;

    .card-img-top{
      img{
        border-radius: 10px;
      }
    }

    &:hover{
        // box-shadow:  20px 20px 60px #161616, -20px -20px 60px #2c2c2c;

    }
`;

export const GatsbyImg = styled(GatsbyImage)`
    border-radius: 10px;
`;

export const VendorName = styled.small`
    opacity: 0.8;
    font-size: 12px;
    font-family: ${({theme}) => theme.fonts.font2};
`;

export const ProductTitle = styled(Card.Title)`
    font-weight: 400;
    line-height: 18px;
    font-size: ;
`;

export const ProductTitleV = styled(Card.Title)`
    font-weight: 400;
    line-height: 24px;
`;

export const Price = styled(Card.Text)`
    font-weight: 400;
    font-family: ${({theme}) => theme.fonts.font2};
    font-size: 1.3rem;
`;

export const PriceV = styled(Card.Text)`
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1rem;
`;


export const AddtoCart = styled(Button)`
    background: transparent;
    border: 0;
    color: ${({theme}) => theme.accent.primary};
    width: 100%;
    border-radius: 10px;

    &:hover{
        background: ${({theme}) => theme.accent.primary};
        border: 0;
        color: ${({theme}) => theme.textAlt};
        
    }
`;