import { Form, Button, Accordion } from 'react-bootstrap'
import styled from 'styled-components'

export const AcCustom = styled(Accordion)`
  border-radius: 10px;
`
export const AcItem = styled(AcCustom.Item)`
  border: 0;
  background: ${({theme}) => theme.accordion.body};
  border-radius: 10px;

  &:last-of-type{
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:first-of-type{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`

export const AcHeader = styled(AcCustom.Header)`
  font-weight: 400;
  border-radius: 10px;

  .accordion-button{
    background: ${({theme}) => theme.accordion.headerBg};
    color: ${({theme}) => theme.accordion.text};
  }
`
export const ClearBtn = styled(Button)`
  background: transparent;
  border: 0;

  &:hover{
    color: ${({theme}) => theme.accent.primary.color};
    background: transparent;
  }

`
export const AcBody = styled(AcCustom.Body)`
  background: ${({theme}) => theme.accordion.body};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: auto;
  max-height: 40vh;
  overflow-y: scroll;
`

export const CustomCheck = styled(Form.Check)`
  .form-check-input{
    background-color: ${({theme}) => theme.inputBg};
  }
`