import * as React from "react"
import { CheckFilter } from "./check-filter"
import { CurrencyField } from "./currency-field"
import { AcCustom, AcItem, AcHeader, AcBody } from "./check-filter.styles"
// import {
//   priceFilterStyle,
//   clearButton,
//   summary,
// } from "./filters.module.css"

export function Filters({
  currencyCode,
  productTypes,
  tags,
  vendors,
  filters,
  setFilters,
}) {
  const updateFilter = (key, value) => {
    setFilters((filters) => ({ ...filters, [key]: value }))
  }

  const updateNumeric = (key, value) => {
    if (
      !isNaN(Number(value)) ||
      (value.endsWith(".") && !isNaN(Number(value.substring(0, -1))))
    ) {
      updateFilter(key, value)
    }
  }

  return (
    <>
      <CheckFilter name="Type" items={productTypes} selectedItems={filters.productTypes} setSelectedItems={(value) => updateFilter("productTypes", value)} />
      <AcCustom defaultActiveKey="0">
        <AcItem eventKey="0">
          <AcHeader >
            Price
            {(filters.maxPrice || filters.minPrice) && (
              <a
                className="float-end"
                href="#clear"
                onClick={(e) => {
                  e.preventDefault()
                  setFilters((filters) => ({
                    ...filters,
                    maxPrice: "",
                    minPrice: "",
                  }))
                } }
              >
                Reset
              </a>
            )}
          </AcHeader>
          <AcBody>
            <CurrencyField
              {...currencyCode}
              aria-label="Minimum price"
              value={filters.minPrice}
              onChange={(event) =>
                updateNumeric("minPrice", event.currentTarget.value)
              }
            />{" "}
            to{" "}
            <CurrencyField
              {...currencyCode}
              aria-label="Maximum price"
              value={filters.maxPrice}
              onChange={(event) =>
                updateNumeric("maxPrice", event.currentTarget.value)
              }
            />
          </AcBody>
        </AcItem>
      </AcCustom>
      <CheckFilter
        name="Brands"
        items={vendors}
        selectedItems={filters.vendors}
        setSelectedItems={(value) => updateFilter("vendors", value)}
      />
      <CheckFilter
        open={true}
        name="Tags"
        items={tags}
        selectedItems={filters.tags}
        setSelectedItems={(value) => updateFilter("tags", value)}
      />
    </>
  )
}
