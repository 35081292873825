import styled from 'styled-components'

export const Main = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "search" "results" "filters";

    @media (min-width: 1024px) { 
            grid-template-columns: max-content 1fr;
            grid-template-areas:
              "search search"
              "filters results";
            grid-gap: 1rem;
    }

    @media (min-width: 1280px) { 
            grid-template-areas:
              "filters search"
              "filters results";
            padding-top: 0.5em;
    }
`

export const Search  = styled.div`
        position: relative;
        grid-area: search;
        height: min-content;
        display: grid;
        grid-template-columns: auto max-content;
        align-items: center;
        padding: 0rem 1rem 1rem 2rem;
`