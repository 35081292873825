import styled from 'styled-components'
import {Form, FormControl, Button} from 'react-bootstrap'

export const SearchForm = styled(Form)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "input";
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
`

export const FormControlStyled = styled(FormControl)`
    background: ${({theme}) => theme.inputBg.elev2} !important;
    color: ${({theme}) => theme.text} !important;
    border: 0;
    border-radius: 10px;
    padding-left: 1rem !important;
    height: 3rem !important;
    grid-area: input;
`

export const ClearSearch = styled(Button)`
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
`